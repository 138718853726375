import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MeasurementUnit } from "../types";

const measurementUnitSlice = createSlice({
    name: "measurementUnit",
    initialState: MeasurementUnit.meter,
    reducers: {
        setMeasurementUnit: (state, action: PayloadAction<MeasurementUnit>) => {
            return action.payload
        }
    }
});

export const { setMeasurementUnit } = measurementUnitSlice.actions;
export default measurementUnitSlice.reducer;