import * as posenet from "@tensorflow-models/posenet";
import { armAngle, ConnectedPartDistance, connectedPartNames, ConnectedParts, connectedPartsList, ConnectedPartsName, ConnectedPartsSizeResult, FinalConnectedPartsName, SizeResult, SizeResultComplete } from "./connected-parts";


export const poseSampleCountRequirement = 50; 

class TwoConnectedParts {
    protected a: ConnectedPartsName;
    protected b: ConnectedPartsName;

    constructor(a: ConnectedPartsName, b: ConnectedPartsName) { 
        this.a = a;
        this.b = b;
    }

    public includes(cp: ConnectedPartsName) {
        return this.a === cp || this.b === cp;
    }

    public getFirst() {
        return this.a;
    }

    public getOther(cp: ConnectedPartsName) {
        if (cp === this.a) {
            return this.b;
        }
        else if (cp === this.b) {
            return this.a;
        }
        else {
            throw new Error("Invalid args!");
        }
    }

    public eq(r: TwoConnectedParts) {
        return (this.a === r.a && this.b === r.b) || (this.b === r.a && this.a === r.b);
    }

    public getKey() {
        if (this.a.localeCompare(this.b)) {
            return this.a + ";" + this.b;
        }
        else {
            return this.b + ";" + this.a;
        }
    }

    public static fromKey(key: string) {
        const [a,b] = key.split(";");
        return new TwoConnectedParts(a as ConnectedPartsName, b as ConnectedPartsName);
    }
}

class ConnectedPartsRealtion extends TwoConnectedParts{

    private _ratio: number; // a / b

    public get ratio() {
        return this._ratio;
    }

    constructor(a: ConnectedPartsName, b: ConnectedPartsName, ratio: number) { 
        super(a, b);
        this._ratio = ratio;
    }

    public to(a: ConnectedPartsName, b: ConnectedPartsName, aValue: number) {
        if (a === this.a && b === this.b) {
            return aValue / this._ratio;
        }
        else if (a === this.b && b === this.a) {
            return aValue * this._ratio;
        }
        else {
            throw new Error("Invalid args!");
        }
    }

    public toRaw() {
        return new TwoConnectedParts(this.a, this.b);
    }
}

interface GroupedConnectedPartsRealtions {
    raw: TwoConnectedParts;
    relations: ConnectedPartsRealtion[];
}

export class SizeCalculator  {
    private usablePartThreshold: number;
    private relations: ConnectedPartsRealtion[] = [];
    
    private _personHeight: number;
    public set personHeight(v: number) {
        this._personHeight = v;
    }
    public get personHeight() {
        return this._personHeight;
    }

    private get relationsWithoutDistance() {
        return Array.from(new Set(this.relations.map(r => r.getKey()))).map(key => TwoConnectedParts.fromKey(key));
    }

    private get groupedRelations(): GroupedConnectedPartsRealtions[] {
        return this.relationsWithoutDistance.map(r => ({
            raw: r,
            relations: this.relations.filter(rel => rel.eq(r))
        }))
    }

    public get isConstrained() {
        return this.constrainedConnections.length === connectedPartNames.length;
    }
    // This can be done much better
    public get constrainedConnections() {
        if (this.relations.length === 0) return [];

        const relations = this.relationsWithoutDistance;

        const foundCp = new Set<ConnectedPartsName>();
        let walking = new Set<ConnectedPartsName>([relations[0].getFirst()]);

        while (walking.size > 0) {
            const newWalking = new Set<ConnectedPartsName>();
            const walkingArray = Array.from(walking);

            for (const w of walkingArray) {
                for (const r of relations) {
                    if (r.includes(w)) {
                        const found = r.getOther(w);
                        if (!walking.has(found) && !foundCp.has(found)) {
                            newWalking.add(found);
                        }
                    }
                }
            }

            for (const w of walkingArray) {
                foundCp.add(w);
            }

            walking = newWalking;
        }

        return Array.from(foundCp);
    }

    public get connectionsMissingConstraints() {
        const allPossibleConnections = new Set(connectedPartNames);
        for (const constrainedConnection of this.constrainedConnections) {
            allPossibleConnections.delete(constrainedConnection);
        }
        return Array.from(allPossibleConnections);
    }

    constructor(personHeight: number, usablePartThreshold: number = 0.7) {
        this._personHeight = personHeight;
        this.usablePartThreshold = usablePartThreshold;
    }

    public addPose(pose: posenet.Pose) {
        const usablePoints = new Map<string, posenet.Keypoint>(pose.keypoints.filter(kp => kp.score >= this.usablePartThreshold).map(kp => [kp.part, kp]));
        
        const connections: ConnectedPartDistance[] = [];
        for (const cp of connectedPartsList) {
            const kp1 = usablePoints.get(cp.parts[0]);
            const kp2 = usablePoints.get(cp.parts[1]);

            if (kp1 && kp2) {
                connections.push({
                    distance: Math.sqrt(Math.pow(kp1.position.x - kp2.position.x, 2) + Math.pow(kp1.position.y - kp2.position.y, 2)),
                    name: cp.name
                });
            }
        }

        const dedupedConnections = this.deduplicateConnections(connections);
        for (let i = 0; i < dedupedConnections.length; i++) {
            const a = dedupedConnections[i];

            for (let k = i + 1; k < dedupedConnections.length; k++) {
                const b = dedupedConnections[k];
                this.relations.push(new ConnectedPartsRealtion(a.name, b.name, a.distance / b.distance));
            }
        }
    }

    /**
     * calculateSizes
     */
    public calculateSizes(): SizeResultComplete {
        const groupedRelations = this.groupedRelations.sort((a, b) => b.relations.length - a.relations.length);
        const deletedGroupedRelations = Array<boolean>(groupedRelations.length).fill(false);
        let deletedRelationsCount = 0;
        
        let cpDone = new Set<ConnectedPartsName>();
        let cpPending = new Set<ConnectedPartsName>(["hipToShoulder"]);

        const sizeMap = new Map<ConnectedPartsName, number[]>();
        sizeMap.set("hipToShoulder", [1]);

        while (cpPending.size > 0 && deletedRelationsCount < groupedRelations.length) {
            const cpPendingArray = Array.from(cpPending);
            const newPending = new Set<ConnectedPartsName>();

            for (const cpCurrent of cpPendingArray) {
                for (let i = 0; i < groupedRelations.length; i++) {
                    if (deletedGroupedRelations[i]) continue;

                    const r = groupedRelations[i];
                    if (r.raw.includes(cpCurrent)) {
                        const other = r.raw.getOther(cpCurrent);

                        const sizeMapCurrent = sizeMap.get(cpCurrent)!;
                        const aValue = SizeCalculator.getMid50Avr(sizeMapCurrent);
                        sizeMap.set(other, 
                            [ 
                                ...(sizeMap.get(other) || []), ...r.relations.map(rel => rel.to(cpCurrent, other, aValue))
                            ].sort((a, b) => a - b));
                        
                        if (!cpDone.has(other) && !cpPending.has(other)) {
                            newPending.add(other);
                        }
                        
                        deletedGroupedRelations[i] = true;
                        deletedRelationsCount++;
                    }
                } 
                
                cpDone.add(cpCurrent);
            }

            cpPending = newPending;
        }
        return this.createResultFromMap(sizeMap);
    }

    private createResultFromMap(data: Map<ConnectedPartsName, number[]>) {
        const result: SizeResult = {};
        data.forEach((sizes, name) => {
            result[name] = {
                distance: SizeCalculator.getMid50Avr(sizes),
                sampleCount: this.relations.filter(r => r.includes(name)).length
            };
        });

        SizeCalculator.imagineConnectedBodyParts(result);

        if (result.earToEar) {
            result.noseToTop = { ...result.earToEar };
        }
        SizeCalculator.applyTorsoHeight(result);
        SizeCalculator.applyNoseToChest(result);
        SizeCalculator.applyTotalHeight(result);
        SizeCalculator.applyTotalWidth(result);

        if (result.totalHeight) {
            const scale = this._personHeight / result.totalHeight.distance;

            for (const name in result) {
                const cpSizeResult = result[name as FinalConnectedPartsName];
                if (cpSizeResult) {
                    cpSizeResult.distance = cpSizeResult.distance * scale;
                }
            }
        }

        return result as SizeResultComplete;
    }

    public static scaleCompleteSizeResult(result: SizeResultComplete, maxHeight: number, maxWidth = Number.POSITIVE_INFINITY) : SizeResultComplete {
        const scaledResult: SizeResult = {};

        const scale = Math.min(maxHeight / result.totalHeight.distance, maxWidth / result.totalWidth.distance);

        for (const name in result) {
            scaledResult[name as FinalConnectedPartsName] = {
                sampleCount: result[name as FinalConnectedPartsName].sampleCount,
                distance: result[name as FinalConnectedPartsName].distance * scale,
            };
        }

        return scaledResult as SizeResultComplete;
    }

    public static getArmTriangleWidth(elbowToShoulder: ConnectedPartsSizeResult) {
        return Math.sin(armAngle) * elbowToShoulder.distance;
    }
    public static getArmTriangleHeight(elbowToShoulder: ConnectedPartsSizeResult) {
        return Math.cos(armAngle) * elbowToShoulder.distance;
    }

    private static imagineConnectedBodyParts(data: SizeResult) {
        let addedPart = true;
        while (addedPart) {
            addedPart = false;

            if (!data.elbowToShoulder && data.hipToShoulder) {
                data.elbowToShoulder = {
                    distance: data.hipToShoulder.distance * 0.5,
                    sampleCount: 0
                };
                addedPart = true;
            }
            if (!data.elbowToWrist && data.elbowToShoulder) {
                data.elbowToWrist = {
                    distance: data.elbowToShoulder.distance,
                    sampleCount: 0
                };
                addedPart = true;
            }

            if (!data.shoulderToShoulder && data.elbowToShoulder) {
                data.shoulderToShoulder = {
                    distance: data.elbowToShoulder.distance,
                    sampleCount: 0
                };
                addedPart = true;
            }
            if (!data.hipToHip && data.hipToShoulder) {
                data.hipToHip = {
                    distance: data.hipToShoulder.distance * 0.3,
                    sampleCount: 0
                };
                addedPart = true;
            }

            if (!data.earToEar && data.shoulderToShoulder) {
                data.earToEar = {
                    distance: data.shoulderToShoulder.distance * 0.5,
                    sampleCount: 0
                };
                addedPart = true;
            }

            if (!data.noseToEar && data.earToEar) {
                data.noseToEar = {
                    distance: data.earToEar.distance * 0.4,
                    sampleCount: 0
                };
                addedPart = true;
            }

            if (!data.earToShoulder && data.shoulderToShoulder) {
                data.earToShoulder = {
                    distance: data.shoulderToShoulder.distance * 0.75,
                    sampleCount: 0
                };
                addedPart = true;
            }

            if (!data.noseToShoulder && data.shoulderToShoulder) {
                data.noseToShoulder = {
                    distance: data.shoulderToShoulder.distance * 0.75,
                    sampleCount: 0
                };
                addedPart = true;
            }

            if (!data.hipToKnee && data.hipToShoulder) {
                data.hipToKnee = {
                    distance: data.hipToShoulder.distance * 0.8,
                    sampleCount: 0
                };
                addedPart = true;
            }
            if (!data.kneeToAnkle && data.hipToKnee) {
                data.kneeToAnkle = {
                    distance: data.hipToKnee.distance,
                    sampleCount: 0
                };
                addedPart = true;
            }
        }
    }

    private static applyTotalHeight(result: SizeResult) {
        if (result.noseToChest && result.torsoHeight && result.hipToKnee && result.kneeToAnkle && result.noseToTop) {
            result.totalHeight = { 
                distance: result.noseToTop.distance + result.noseToChest.distance + result.torsoHeight.distance + 
                    result.hipToKnee.distance + result.kneeToAnkle.distance,
                sampleCount: Math.min(result.noseToTop.sampleCount, result.noseToChest.sampleCount, 
                    result.torsoHeight.sampleCount, result.hipToKnee.sampleCount, result.kneeToAnkle.sampleCount)
            };
        }
    }
    // only public for testing
    public static applyTotalWidth(result: SizeResult) {
        // add legs -> hip to this
        if (result.shoulderToShoulder && result.elbowToShoulder) {
            result.totalWidth = { 
                distance: Math.sin(armAngle) * result.elbowToShoulder.distance * 2 + result.shoulderToShoulder.distance,
                sampleCount: Math.min(result.shoulderToShoulder.sampleCount, 
                    result.elbowToShoulder.sampleCount)
            };
        }
    }
    private static applyNoseToChest(result: SizeResult) {
        if (result.noseToShoulder && result.shoulderToShoulder) {
            result.noseToChest = { 
                distance: Math.sqrt(Math.pow(result.noseToShoulder.distance, 2) - Math.pow(result.shoulderToShoulder.distance * 0.5, 2)),
                sampleCount: Math.min(result.noseToShoulder.sampleCount, result.shoulderToShoulder.sampleCount)
            };
        }
    }
    private static applyTorsoHeight(result: SizeResult) {
        if (result.hipToHip && result.hipToShoulder && result.shoulderToShoulder) {
            result.torsoHeight = { 
                distance: Math.sqrt(Math.pow(result.hipToShoulder.distance, 2) - Math.pow((result.shoulderToShoulder.distance - result.hipToHip.distance) * 0.5, 2)),
                sampleCount: Math.min(result.hipToHip.sampleCount, result.hipToShoulder.sampleCount, result.shoulderToShoulder.sampleCount)
            };
        }
    }

    /**
     * This implementation only allow 2 of the same type. This should be the max anyway.
     * @param connections 
     * @returns deduped connections
     */
    private deduplicateConnections(connections: ConnectedPartDistance[]) : ConnectedPartDistance[] {
        const connectionMap = new Map<string, number>();
        for (const c of connections) {
            if (connectionMap.has(c.name)) {
                connectionMap.set(c.name, (connectionMap.get(c.name)! + c.distance) / 2);
            }
            else {
                connectionMap.set(c.name, c.distance);
            }
        }
        return Array.from(connectionMap.entries()).map(([ name, distance ]) => ({
            name: name as ConnectedPartsName, 
            distance
        }))
    }

    private static getMid50Avr(values: number[]) {
        if (values.length < 4) {
            return values.reduce((pv, cv) => pv + cv, 0) / values.length;
        }
        else {
            const onePart = Math.floor(values.length * 0.25)
            return values.slice(onePart, 3*onePart).reduce((pv, cv) => pv + cv, 0) / (2*onePart);    
        }
    }
}