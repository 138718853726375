export type ConnectedPartsName = "noseToEar" | "earToShoulder" | "noseToShoulder" | 
        "shoulderToShoulder" | "elbowToShoulder" | "elbowToWrist" | 
        "hipToShoulder" | "hipToHip" | "hipToKnee" | "kneeToAnkle" | "earToEar";

export type CalculatedConnectedPartsName = "noseToChest" | "totalHeight" | "noseToTop" | "torsoHeight" | "totalWidth";
export type FinalConnectedPartsName = ConnectedPartsName | CalculatedConnectedPartsName;


export interface ConnectedParts {
    name: ConnectedPartsName;
    parts: [string, string];
}

export const armAngle = (20 / 360) * 2 * Math.PI;
export const connectedPartNames: ConnectedPartsName[] = [
    "noseToEar", "earToShoulder", "noseToShoulder", 
        "shoulderToShoulder", "elbowToShoulder", "elbowToWrist", 
        "hipToShoulder", "hipToHip", "hipToKnee", "kneeToAnkle", "earToEar"
]
export interface ConnectedPartsSizeResult {
    distance: number;
    sampleCount: number;
};
export type SizeResult = { [key in FinalConnectedPartsName]?: ConnectedPartsSizeResult }
export type SizeResultComplete = { [key in FinalConnectedPartsName]: ConnectedPartsSizeResult }

export interface PosePoint {
    x: number;
    y: number;
}

export interface PoseLine {
    a: PosePoint,
    b: PosePoint,
    sampleCount: number;
}

export interface ConnectedPartDistance {
    name: ConnectedPartsName;
    distance: number;
}

export const connectedPartsList: ConnectedParts[] = [
    {
        name: "noseToEar",
        parts: ["leftEar", "nose"]
    }, 
    {
        name: "noseToEar",
        parts: ["rightEar", "nose"]
    },
    {
        name: "earToShoulder",
        parts: ["leftEar", "leftShoulder"]
    }, 
    {
        name: "earToShoulder",
        parts: ["rightEar", "rightShoulder"]
    }, 
    {
        name: "earToEar",
        parts: ["leftEar", "rightEar"]
    }, 
    {
        name: "noseToShoulder",
        parts: ["nose", "rightShoulder"]
    }, 
    {
        name: "noseToShoulder",
        parts: ["nose", "leftShoulder"]
    }, 
    {
        name: "shoulderToShoulder",
        parts: ["leftShoulder", "rightShoulder"]
    }, 
    {
        name: "elbowToShoulder",
        parts: ["leftShoulder", "leftElbow"]
    }, 
    {
        name: "elbowToShoulder",
        parts: ["rightElbow", "rightShoulder"]
    }, 
    {
        name: "elbowToWrist",
        parts: ["leftElbow", "leftWrist"]
    },
    {
        name: "elbowToWrist",
        parts: ["rightElbow", "rightWrist"]
    },
    {
        name: "hipToShoulder",
        parts: ["leftHip", "leftShoulder"]
    },
    {
        name: "hipToShoulder",
        parts: ["rightHip", "rightShoulder"]
    },
    {
        name: "hipToHip",
        parts: ["leftHip", "rightHip"]
    }, 
    {
        name: "hipToKnee",
        parts: ["leftHip", "leftKnee"]
    },
    {
        name: "hipToKnee",
        parts: ["rightHip", "rightKnee"]
    },
    {
        name: "kneeToAnkle",
        parts: ["leftAnkle", "leftKnee"]
    },
    {
        name: "kneeToAnkle",
        parts: ["rightAnkle", "rightKnee"]
    },
];