import { PoseLine, PosePoint, SizeResultComplete } from "../../lib/connected-parts";
import { SizeCalculator } from "../../lib/size-calculator";

export function drawPose(pose: SizeResultComplete, bottomNeck: PosePoint, markedPoints: PosePoint[], markedLines: PoseLine[]) {
    const pushLinePoints = (line: PoseLine) => {
        markedPoints.push(line.a, line.b)
    };

    const shouldToShoulder: PoseLine = {
        a: {
            y: bottomNeck.y,
            x: bottomNeck.x + pose.shoulderToShoulder.distance / 2
        },
        b: {
            y: bottomNeck.y,
            x: bottomNeck.x - pose.shoulderToShoulder.distance / 2
        },
        sampleCount: pose.shoulderToShoulder.sampleCount
    };
    pushLinePoints(shouldToShoulder);
    markedLines.push(shouldToShoulder);

    const elbowToShoulderLeft: PoseLine = {
        a: shouldToShoulder.b,
        b: {
            x: shouldToShoulder.b.x - SizeCalculator.getArmTriangleWidth(pose.elbowToShoulder),
            y: shouldToShoulder.b.y + SizeCalculator.getArmTriangleHeight(pose.elbowToShoulder),
        },
        sampleCount: pose.elbowToShoulder.sampleCount
    };
    markedPoints.push(elbowToShoulderLeft.b);
    markedLines.push(elbowToShoulderLeft);

    const elbowToWristLeft: PoseLine = {
        a: elbowToShoulderLeft.b,
        b: {
            x: elbowToShoulderLeft.b.x,
            y: elbowToShoulderLeft.b.y + pose.elbowToWrist.distance
        },
        sampleCount: pose.elbowToWrist.sampleCount
    };
    markedPoints.push(elbowToWristLeft.b);
    markedLines.push(elbowToWristLeft);

    const elbowToShoulderRight: PoseLine = {
        a: shouldToShoulder.a,
        b: {
            x: shouldToShoulder.a.x + SizeCalculator.getArmTriangleWidth(pose.elbowToShoulder),
            y: shouldToShoulder.a.y + SizeCalculator.getArmTriangleHeight(pose.elbowToShoulder),
        },
        sampleCount: pose.elbowToShoulder.sampleCount
    };
    markedPoints.push(elbowToShoulderRight.b);
    markedLines.push(elbowToShoulderRight);

    const elbowToWristRight: PoseLine = {
        a: elbowToShoulderRight.b,
        b: {
            x: elbowToShoulderRight.b.x,
            y: elbowToShoulderRight.b.y + pose.elbowToWrist.distance
        },
        sampleCount: pose.elbowToWrist.sampleCount
    };
    markedPoints.push(elbowToWristRight.b);
    markedLines.push(elbowToWristRight);

    const betweenHips: PosePoint = {
        x: bottomNeck.x,
        y: bottomNeck.y + pose.torsoHeight.distance
    };

    const hipToHip: PoseLine = {
        a: {
            x: betweenHips.x - pose.hipToHip.distance / 2,
            y: betweenHips.y
        },
        b: {
            x: betweenHips.x + pose.hipToHip.distance / 2,
            y: betweenHips.y
        },
        sampleCount: pose.hipToHip.sampleCount
    };
    pushLinePoints(hipToHip);
    markedLines.push(hipToHip);

    const hipToShoulderLeft: PoseLine = {
        a: hipToHip.a,
        b: shouldToShoulder.b,
        sampleCount: pose.hipToShoulder.sampleCount
    };
    markedLines.push(hipToShoulderLeft);

    const hipToShoulderRight: PoseLine = {
        a: hipToHip.b,
        b: shouldToShoulder.a,
        sampleCount: pose.hipToShoulder.sampleCount
    };
    markedLines.push(hipToShoulderRight);

    const hipToKneeLeft: PoseLine = {
        a: hipToHip.a,
        b: {
            x: hipToHip.a.x,
            y: hipToHip.a.y + pose.hipToKnee.distance
        },
        sampleCount: pose.hipToKnee.sampleCount
    };
    markedPoints.push(hipToKneeLeft.b);
    markedLines.push(hipToKneeLeft);

    const kneeToAnkleLeft: PoseLine = {
        a: hipToKneeLeft.b,
        b: {
            x: hipToKneeLeft.b.x,
            y: hipToKneeLeft.b.y + pose.kneeToAnkle.distance
        },
        sampleCount: pose.kneeToAnkle.sampleCount
    };
    markedPoints.push(kneeToAnkleLeft.b);
    markedLines.push(kneeToAnkleLeft);

    const hipToKneeRight: PoseLine = {
        a: hipToHip.b,
        b: {
            x: hipToHip.b.x,
            y: hipToHip.b.y + pose.hipToKnee.distance
        },
        sampleCount: pose.hipToKnee.sampleCount
    };
    markedPoints.push(hipToKneeRight.b);
    markedLines.push(hipToKneeRight);

    const kneeToAnkleRight: PoseLine = {
        a: hipToKneeRight.b,
        b: {
            x: hipToKneeRight.b.x,
            y: hipToKneeRight.b.y + pose.kneeToAnkle.distance
        },
        sampleCount: pose.kneeToAnkle.sampleCount
    };
    markedPoints.push(kneeToAnkleRight.b);
    markedLines.push(kneeToAnkleRight);
}