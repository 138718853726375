import "./index.scss";
import { useEffect, useRef, useState } from "react";
import * as posenet from '@tensorflow-models/posenet';
import { VideoSource } from "../../types";

const connectToCamera = async (constraints: MediaStreamConstraints, retryDelay: number) => {
    try {
        return await navigator.mediaDevices.getUserMedia(constraints);
    } catch {
        return await new Promise<MediaStream>((res, rej) => {
            const intervalHandle = setInterval(() => {
                try {
                    navigator.mediaDevices.getUserMedia(constraints).then(stream => {
                        clearInterval(intervalHandle);
                        res(stream);
                    }).catch(() => console.log("error while connecting to camera! retrying!"));
                } catch {
                    
                }
            }, retryDelay);
        });
    }
};

export function CameraFeed(props: { 
        onPose?: (pose: posenet.Pose) => void, 
        onCameraConnected?: (connected: boolean) => void,
        source: VideoSource
    }) {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [model, setModel] = useState<posenet.PoseNet>();
    const [videoStoppedHandle, setVideoStoppedHandle] = useState(0);

    useEffect(() => {    
        posenet.load({
            architecture: "ResNet50",
            inputResolution: {
                height: 300,
                width: 400
            },
            outputStride: 16
        }).then(m => {
            setModel(m);
        })
    }, []);

    useEffect(() => {
        if (!videoRef.current || !model) return;

        let isClosed: boolean = false;
        let disposers: (() => void)[] = [
            () => { isClosed = true }
        ];
        const addDisposer = (disposer: () => void) => {
            if (isClosed) {
                disposer();
            }
            else {
                disposers.push(disposer);
            }
        };

        (async () => {
            const videoInputWidth = 960;
            const videoInputHeight = 720;

            const nnImgWidth = 400;
            const nnImgHeight = 300;

            const constraints: MediaStreamConstraints = { audio: false, video: { facingMode: "front", width: videoInputWidth, height: videoInputHeight } };

            const saveImgCanvas = document.createElement('canvas');
            saveImgCanvas.width = nnImgWidth;
            saveImgCanvas.height = nnImgHeight;

            const saveImgContext = saveImgCanvas.getContext("2d");
            if (!saveImgContext) {
                return;
            }

            addDisposer(() => {
                saveImgCanvas.remove();
                if (videoRef.current) {
                    videoRef.current.srcObject = null;
                }
            });
            console.log("video video video")

            if (props.source.type === "file") {
                const streamEndedHandler = () => {
                    setVideoStoppedHandle(pv => pv + 1);
                    console.log("end video");
                    props.onCameraConnected?.call(null, false);
                };

                videoRef.current!.addEventListener("ended", streamEndedHandler);
                videoRef.current!.volume = 0;


                var sourceFile = document.createElement('source');
                sourceFile.src = props.source.url;
                sourceFile.type="video/mp4"
                videoRef.current!.appendChild(sourceFile);

                addDisposer(() => {
                    videoRef.current?.removeChild(sourceFile);
                    videoRef.current?.removeEventListener("ended", streamEndedHandler);
                });

            }
            else {
                const stream = await connectToCamera(constraints, 1000);
                const firstVideoTrack: MediaStreamTrack | undefined = stream.getVideoTracks()[0];
                const streamEndedHandler = () => {
                    setVideoStoppedHandle(pv => pv + 1);
                    props.onCameraConnected?.call(null, false);
                };
    
                firstVideoTrack?.addEventListener("ended", streamEndedHandler);
    
                addDisposer(() => {
                    firstVideoTrack?.removeEventListener("ended", streamEndedHandler);
                    stream.getTracks().forEach(track => {
                        track.stop();
                    })
                });

                videoRef.current!.srcObject = stream;
            }

            const poseEstimeIntervalHandle = setInterval(async () => {
                if (!videoRef.current) return;

                saveImgContext.drawImage(videoRef.current!, 0, 0, nnImgWidth, nnImgHeight);
                const result = await model.estimateSinglePose(saveImgCanvas);
                props.onPose?.call(null, result);
            }, 1000);

            addDisposer(() => clearInterval(poseEstimeIntervalHandle));
        })()

        return () => {
            disposers.forEach(d => d());
        };

    }, [videoRef, model, videoStoppedHandle]);

    return (
        <div className="camera-feed">
            <video 
                ref={videoRef} 
                autoPlay={true} 
                playsInline={true}
                onPlay={() => {
                    console.log("play video");
                    props.onCameraConnected?.call(null, true);
                }}></video>
        </div>
    );  
}