import "./index.scss";
import { SizeResultComplete } from "../../lib/connected-parts";
import { useMemo, useState } from "react";
import checkIcon from "./task_alt_black_24dp.svg"
import { useTranslation } from "react-i18next";
import { ActionButton } from "../../components/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { MeasurementUnit } from "../../types";
import { meterToFootAndInch, meterToInchRounded } from "../../util";
import { SwitchUnitButton } from "../../components/SwitchUnitButton";
import { setMeasurementUnit } from "../../store/measurement-unit";
import { SwitchMeasurementUnitButton } from "../../components/SwitchMeasurementUnitButton";

export function ResultPage(props: { pose: SizeResultComplete, onReset: () => void }) {
    const dispatch = useDispatch();
    const measurementUnit = useSelector((state: RootState) => state.measurementUnit);

    const [t] = useTranslation();

    const formatSize = (size: number) => {
        if (measurementUnit === MeasurementUnit.meter) {
            return `${Math.floor(size * 100)}cm`;
        }
        else {
            const inches = meterToInchRounded(size);
            return `${inches}"`;
        }
    };

    return (
        <>
            <div className="toolbar flex-row">
                <div className="flex-spacer"></div>
                <ActionButton onClick={props.onReset}>{t("common:restart")}</ActionButton>
            </div>
            <div className="result-page flex-spacer">
                <div className="result-page__inner">
                    <div className="result-page__checkmark-icon__container">
                        <img src={checkIcon} alt="done" className="result-page__checkmark-icon" />
                    </div>
                    <div className="flex-row flex-row--center-center">
                        <div className="responsive-container--h-spacing">
                            <SwitchMeasurementUnitButton />
                        </div>
                    </div>
                    <div className="height-spacer--extra-large"></div>
                    <table className="result-page__table">
                        <tbody>
                            <tr>
                                <td>{t("common:upper_body_length")}</td>
                                <td>{formatSize(props.pose.torsoHeight.distance)}</td>
                            </tr>
                            <tr>
                                <td>{t("common:shoulder_width")}</td>
                                <td>{formatSize(props.pose.shoulderToShoulder.distance + 0.05)}</td>
                            </tr>
                            <tr>
                                <td>{t("common:arm_length")}</td>
                                <td>{formatSize(props.pose.elbowToShoulder.distance + props.pose.elbowToWrist.distance)}</td>
                            </tr>
                            <tr>
                                <td>{t("common:leg_length")}</td>
                                <td>{formatSize(props.pose.kneeToAnkle.distance + props.pose.hipToKnee.distance)}</td>
                            </tr>
                            <tr>
                                <td>{t("common:hip_width")}</td>
                                <td>{formatSize(props.pose.hipToHip.distance)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>

    );
}