import "./index.scss";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ValueWithUnit } from "../../components/ValueWithUnit";
import { ActionButton } from "../../components/ActionButton";
import { SwitchUnitButton } from "../../components/SwitchUnitButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { MeasurementUnit } from "../../types";
import { setMeasurementUnit } from "../../store/measurement-unit";
import { meterToFootFloor, meterToInchWithExtractedFoot } from "../../util";
import { SwitchMeasurementUnitButton } from "../../components/SwitchMeasurementUnitButton";

export function SizeInputImperial(props: { defaultValue: number, setValue: (value: number) => void }) {
    const [valueFoot, setValueFoot] = useState(() => meterToFootFloor(props.defaultValue));
    const [valueInch, setValueInch] = useState(() => meterToInchWithExtractedFoot(props.defaultValue, valueFoot));

    const [t] = useTranslation();

    const valid = useMemo(() => valueInch > 0 && valueInch < 12 && valueFoot > 0 && valueFoot < 10, [valueFoot, valueInch]);

    const valueMeter = useMemo(() => {
        return valueFoot * 0.3048 + valueInch * 0.0254;
    }, [valueFoot, valueInch]);

    useEffect(() => {
        props.setValue(valueMeter);
    }, [valueMeter]);

    return (
        <>
            <ValueWithUnit unit={t("common:foot")} value={valueFoot} setValue={(v => {
                setValueFoot(Math.floor(v));
            })} valid={valid} />
            <div className="height-spacer"></div>
            <ValueWithUnit unit={t("common:inch")} value={valueInch} setValue={(v => {
                setValueInch(Math.floor(v));
            })} valid={valid} />
        </>
    );
}


export function SizeInputMeter(props: { defaultValue: number, setValue: (value: number) => void }) {
    const [value, setValue] = useState(() => {
        return Math.round(props.defaultValue * 100) / 100;
    });
    const [t] = useTranslation();

    const valid = useMemo(() => value > 0 && value < 3, [value]);

    return (
        <ValueWithUnit unit={t("common:unit_meter")} value={value} setValue={(v => {
            props.setValue(v);
            setValue(v);
        })} valid={valid} />
    );
}

const defaultBodySize = 1.75;

export function SizeInputPage(props: { onContinue: (value: number) => void }) {
    const dispatch = useDispatch();
    const measurementUnit = useSelector((state: RootState) => state.measurementUnit)

    const [t, i18n] = useTranslation();
    const [size, setSize] = useState(() => {
        const sizeStored = localStorage.getItem("body_size");
        if (sizeStored) {
            const parsed = Number(sizeStored);
            if (!Number.isNaN(parsed) && parsed > 0 && parsed < 3) {
                return parsed;
            }
            else {
                return defaultBodySize;
            }
        }
        else {
            return defaultBodySize;
        }
    });

    // const [meterUnit, setMeterUnit] = useState(() => i18n.language !== "en");

    const callOnContinue = () => {
        localStorage.setItem("body_size", String(size));
        props.onContinue(size);
    };

    return (
        <div className="size-input-page flex-spacer flex-column flex-column--center-center">
            <div className="size-input-page__container responsive-container--h-spacing">
                <h1>{t("common:text_enter_body_height")}</h1>
                <SwitchMeasurementUnitButton />
                <div className="height-spacer"></div>
                {measurementUnit === MeasurementUnit.meter ? (
                    <SizeInputMeter defaultValue={size} setValue={v => setSize(v)} />
                ) : (
                    <SizeInputImperial defaultValue={size} setValue={v => setSize(v)} />
                )}
                <div className="height-spacer--large"></div>
                <div className="flex-row">
                    <div className="flex-spacer"></div>
                    <ActionButton onClick={callOnContinue}>
                        {t("common:continue")}
                    </ActionButton>
                </div>
            </div>
        </div>
    );
}