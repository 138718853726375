import "./index.scss";
import { SizeResultComplete } from "../../lib/connected-parts";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { poseSampleCountRequirement, SizeCalculator } from "../../lib/size-calculator";
import { CameraFeed } from "../../components/CameraFeed";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { PoseDisplay } from "../../components/PoseDisplay";
import { StatusBar } from "../../components/StatusBar";
import { ActionButton } from "../../components/ActionButton";

export function PoseScanningPage(props: { personHeight: number, onFinished: (result: SizeResultComplete) => void, onReset: () => void }) {
    const [t] = useTranslation();
    const [calculator, setCalculator] = useState(() => new SizeCalculator(props.personHeight, 0.7));
    const [pose, setPose] = useState(() => calculator.calculateSizes());
    const [cameraConnected, setCameraConnected] = useState(false);

    const scanningProgress = useMemo(() => {
        const values = Object.values(pose);
        const finishedCount = values.map(r => Math.min(r.sampleCount, poseSampleCountRequirement)).reduce((pv, cv) => pv + cv, 0);
        const maxCount = values.length * poseSampleCountRequirement;

        return finishedCount / maxCount;
    }, [pose]);

    useEffect(() => {
        if (!Object.values(pose).some(r => r.sampleCount < poseSampleCountRequirement)) {
            props.onFinished(pose);
        }
    }, [pose]);

    return (
        <>
            <div className="toolbar flex-row">
                <div className="flex-spacer"></div>
                <ActionButton onClick={props.onReset}>{t("common:restart")}</ActionButton>
            </div>
            <div className="pose-scanning-page flex-column flex-column--fill flex-column--center">
                <div className="responsive-container--h-spacing">
                    <div className="pose-scanning-page__infotext">{t("common:text_scan_you_body_from_head_to_toe")}</div>
                    <StatusBar progress={scanningProgress} />
                </div>
                <div className="flex-spacer pose-scanning-page__inner">
                    <CameraFeed
                        onCameraConnected={c => setCameraConnected(c)}
                        source={{ type: "camera" }}
                        onPose={p => {
                            calculator.addPose(p);
                            setPose(() => calculator.calculateSizes());
                        }} />
                    <PoseDisplay pose={pose} />
                    {!cameraConnected && <LoadingOverlay text={t("text_connecting_to_camera")} />}
                </div>
            </div>
        </>
    );
}