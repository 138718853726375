import "./index.scss";

export function LoadingOverlay(props: { text: string }) {
    return (
        <div className="loading-overlay">
            <div className="loading-overlay__spinner"></div>
            <div className="responsive-container--h-spacing">
                <div className="loading-overlay__text">{props.text}</div>
            </div>
        </div>
    );  
}