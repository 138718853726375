import React, { useEffect, useMemo, useState } from 'react';
import { CameraFeed } from '../components/CameraFeed';
import { SizeResultComplete } from '../lib/connected-parts';
import { poseSampleCountRequirement, SizeCalculator } from '../lib/size-calculator';
import { PoseDisplay } from '../components/PoseDisplay';
import { ResultPage } from './ResultPage';
import { SizeInputPage } from './SizeInputPage';
import { PoseScanningPage } from './PoseScanningPage';
import { useDispatch } from 'react-redux';
import { setMeasurementUnit } from '../store/measurement-unit';
import { useTranslation } from 'react-i18next';
import { MeasurementUnit } from '../types';


// function App2() {
//   const [calculator, setCalculator] = useState(() => new SizeCalculator(1.75, 0.7));
//   const [pose, setPose] = useState(() => calculator.calculateSizes());
//   const [sizeDefined, setSizeDefined] = useState(false);
//   const [cameraConnected, setCameraConnected] = useState(false);

//   const [viewSizeInput, viewScanning, viewResult] = useMemo(() => {
//     if (sizeDefined) {
//       if (Object.values(pose).some(r => r.sampleCount < poseSampleCountRequirement)) {
//         return [false, true, false];
//       }
//       else {
//         return [false, false, true];
//       }
//     }
//     else {
//       return [true, false, false];
//     }
//   }, [sizeDefined, pose]);

//   return (
//     <div className="app">
//       {viewSizeInput && (
//         <SizeInputPage onContinue={(s) => {
//           calculator.personHeight = s;
//           setSizeDefined(true);
//         }} />
//       )}
//       {viewScanning && (
//         <>
//           <CameraFeed onPose={pose => {
//             calculator.addPose(pose);
//             setPose(calculator.calculateSizes())
//           }} 
//           onCameraConnected={connected => setCameraConnected(connected)}/>
//           <PoseDisplay pose={pose} />
//           {!cameraConnected && <CameraLoading/>}
//         </>
//       )}
//       {viewResult && (
//         <ResultPage pose={pose} />
//       )}
//     </div>
//   );
// }

function App() {
  const [pose, setPose] = useState<SizeResultComplete | undefined>(undefined);
  const [personHeight, setPersonHeight] = useState<number | undefined>(undefined);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    dispatch(setMeasurementUnit(i18n.language === "en" ? MeasurementUnit.foorInch : MeasurementUnit.meter));
  }, []);

  const [viewSizeInput, viewScanning, viewResult] = useMemo(() => {
    if (personHeight !== undefined) {
      if (pose !== undefined) {
        return [false, false, true];
      }
      else {
        return [false, true, false];
      }
    }
    else {
      return [true, false, false];
    }
  }, [personHeight, pose]);

  const reset = () => {
    setPose(undefined);
    setPersonHeight(undefined);
  };

  return (
    <div className="flex-column flex-column--fill">
      {viewSizeInput && (
        <SizeInputPage onContinue={ph => setPersonHeight(ph)} />
      )}
      {viewScanning && (
        <PoseScanningPage
          personHeight={personHeight!}
          onReset={reset}
          onFinished={p => setPose(p)} />
      )}
      {viewResult && (
        <ResultPage
          onReset={reset}
          pose={pose!} />
      )}
    </div>
  );
}

export default App;
