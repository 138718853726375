import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setMeasurementUnit } from "../../store/measurement-unit";
import { MeasurementUnit } from "../../types";
import { SwitchUnitButton } from "../SwitchUnitButton";

export function SwitchMeasurementUnitButton() {
    const dispatch = useDispatch();
    const measurementUnit = useSelector((state: RootState) => state.measurementUnit)
    const [t] = useTranslation();

    if (measurementUnit === MeasurementUnit.meter) {
        return (
            <SwitchUnitButton onClick={() => dispatch(setMeasurementUnit(MeasurementUnit.foorInch))}>
                {t("common:switch_units_foot_inch")}
            </SwitchUnitButton>
        );
    }
    else {
        return (
            <SwitchUnitButton onClick={() => dispatch(setMeasurementUnit(MeasurementUnit.meter))}>
                {t("common:switch_units_meter")}
            </SwitchUnitButton>
        );
    }
}