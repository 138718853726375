export enum MeasurementUnit {
    meter,
    foorInch
}

export type VideoSourceCamera = {
    type: "camera"
}

export type VideoSourceFile = {
    type: "file",
    url: string;
}

export type VideoSource = VideoSourceCamera | VideoSourceFile;