import "./index.scss";
import useResizeObserver from '@react-hook/resize-observer';
import { useEffect, useMemo, useRef, useState } from "react";
import { PoseLine, PosePoint, SizeResultComplete } from "../../lib/connected-parts";
import { poseSampleCountRequirement, SizeCalculator } from "../../lib/size-calculator";
import { drawPose } from "./draw";
import chroma from "chroma-js";

const finalColor = "#3535ad";

function getColorBySampleCount(sampleCount: number) {
    if (sampleCount === 0) {
        return "#aaa";
    } 
    else if (sampleCount < poseSampleCountRequirement) {
        return chroma.scale([ "#99f", "#55f" ])((sampleCount - 1) / (poseSampleCountRequirement - 2)).css();
    }
    else {
        return finalColor;
    }
}

const markedPointSizeDenominator = 25;

export function PoseDisplay(props: { pose: SizeResultComplete }) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvasHostRef = useRef<HTMLDivElement>(null);
    const [rescalePoseHandle, setRescalePoseHandle] = useState(0);
    const scaledPosRef = useRef<SizeResultComplete>();
    const scaledPosRefChanged = useRef<boolean>();

    const calculatePointSize = () => {
        // 100 is completely random, it should never have to fallback
        const w = canvasRef.current?.width || 100;
        const h = canvasRef.current?.height || 100;

        if (h / w > 1.5) {
            return (w*1.5) / markedPointSizeDenominator;
        }
        else if (w / h > 1.25) {
            return (h*1.25) / markedPointSizeDenominator;
        }
        else {
            return (w + h) / (markedPointSizeDenominator*2);
        }
    };

    const scaledPose = useMemo(() => {
        const pointSize = calculatePointSize();

        return SizeCalculator.scaleCompleteSizeResult(props.pose, 
                (canvasRef.current?.height || 100) - pointSize, 
                (canvasRef.current?.width || 100) - pointSize); // 100 is completely random, it should never have to fallback
    }, [props.pose, rescalePoseHandle]);

    useEffect(() => {
        scaledPosRef.current = scaledPose;
        scaledPosRefChanged.current = true;
    }, [scaledPose]);

    useResizeObserver(canvasHostRef, () => {
        if (canvasRef.current && canvasHostRef.current) {
            canvasRef.current.width = canvasHostRef.current.clientWidth;
            canvasRef.current.height = canvasHostRef.current.clientHeight;
            setRescalePoseHandle(pv => pv + 1);
        }
    });

    const canvasContext = useMemo(() => {
        return canvasRef.current?.getContext("2d");
    }, [canvasRef.current]);

    useEffect(() => {
        if (!canvasRef.current || !canvasContext) return;
        console.log("ready to render!");
        let isClosed = false;
        let frameCounter = 0;

        const render = () => {
            frameCounter++;
            
            if (!isClosed) {
                requestAnimationFrame(render);
            }

            // Framerate controller to reduce cpu usage
            if (!scaledPosRefChanged.current) {
                if (frameCounter % 8 !== 0) {
                    return;
                }    
            }
            else {
                scaledPosRefChanged.current = false;
            }

            if (!canvasRef.current || !canvasContext || !scaledPosRef.current) return;

            canvasContext.save();
            canvasContext.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
            const markedPointSize = calculatePointSize();
            const markedPointSizeHalf = markedPointSize / 2;
            const markedLineWidth = markedPointSize / 3;

            const xOffset = (canvasRef.current.width - scaledPosRef.current.totalWidth.distance) / 2;
            const yOffset = (canvasRef.current.height - scaledPosRef.current.totalHeight.distance) / 2;

            const markedPoints: PosePoint[] = [];
            const markedLines: PoseLine[] = [];

            const bottomNeck: PosePoint = {
                x: xOffset + SizeCalculator.getArmTriangleWidth(scaledPosRef.current.elbowToShoulder) + scaledPosRef.current.shoulderToShoulder.distance / 2,
                y: yOffset + scaledPosRef.current.noseToChest.distance + scaledPosRef.current.noseToTop.distance
            };
            markedPoints.push(bottomNeck);

            const chestToTopDistance = scaledPosRef.current.noseToChest.distance + scaledPosRef.current.noseToTop.distance;
            const headSize = Math.min(
                scaledPosRef.current.earToEar.distance * 1.5,
                scaledPosRef.current.shoulderToShoulder.distance * 0.75,
                chestToTopDistance * 0.8);

            const topNeck: PosePoint = {
                x: bottomNeck.x,
                y: bottomNeck.y - (chestToTopDistance - headSize)
            };

            markedLines.push({
                a: bottomNeck,
                b: topNeck,
                sampleCount: scaledPosRef.current.noseToChest.sampleCount
            });

            drawPose(scaledPosRef.current, bottomNeck, markedPoints, markedLines);

            canvasContext.lineWidth = markedLineWidth;
            for (const markedLine of markedLines) {
                canvasContext.strokeStyle = getColorBySampleCount(markedLine.sampleCount);
                canvasContext.beginPath();
                canvasContext.moveTo(markedLine.a.x, markedLine.a.y);
                canvasContext.lineTo(markedLine.b.x, markedLine.b.y);
                canvasContext.stroke();
            }

            // draw head 
            canvasContext.strokeStyle = getColorBySampleCount(scaledPosRef.current.noseToTop.sampleCount);
            canvasContext.beginPath();
            canvasContext.arc(bottomNeck.x, topNeck.y - headSize / 2, headSize / 2, 0, 2 * Math.PI);
            canvasContext.stroke();

            canvasContext.fillStyle = finalColor;

            for (const markedPoint of markedPoints) {
                canvasContext.beginPath();
                canvasContext.arc(markedPoint.x, markedPoint.y, markedPointSizeHalf, 0, 2 * Math.PI);
                canvasContext.fill();
            }

            canvasContext.restore();
        }

        requestAnimationFrame(render);

        return () => {
            isClosed = true;
        };
    }, [canvasRef, canvasContext])

    return (
        <div className="pose-display flex-column flex-column--center-center">
            <div className="pose-display__inner" ref={canvasHostRef}>
                <canvas ref={canvasRef}></canvas>
            </div>
        </div>
    );
}