import { configureStore, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MeasurementUnit } from "../types";
import measurementUnitReducer from "./measurement-unit";


const store = configureStore({
    reducer: {
        measurementUnit: measurementUnitReducer
    },
});

export default store;

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch